<template>
  <div class="home-item-title">
    <div class="home-item-her">
      <div class="flex">
        <van-icon name="todo-list-o" size="18" />
        <span>{{ item.merchantName }}</span>
      </div>
      <span
        v-if="isShow"
        class="fr f12 grey"
        :class="{ red: item.signStatus == 0 || item.signStatus == 1 }"
        >{{ signStatus }}</span
      >
    </div>

    <div class="home-item-info">
      <div class="flex-info">
        <p class="l-left">单据信息：</p>
        <div class="inline-bk">
          <p v-for="(o, index) in item.infoList" :key="index">
            {{ o.orderTime }}
            <span class="pags">{{ o.orderCode }}</span>
            <span class="amount" v-if="o.cancelFlag">(声明作废)</span>
            <copy-com :oId="o.orderCode"/>
          </p>
        </div>
      </div>
      <p class="flex-info">
        <span class="l-left">单据合计：</span>
        <span class="amount" v-if="item.amount != '-1'">￥{{ item.amount }}</span>
        <span class="pag" v-else>-</span>
      </p>
      <p class="flex-info">
        <span class="l-left">送货地址：</span><span class="pag">{{ item.address }}</span>
      </p>
      <p class="flex-info">
        <span class="l-left">收货客户：</span><span class="pag">{{ item.customerName }}</span>
      </p>
    </div>

    <div v-if="isShow" class="flex home-btn">
      <div class="flex">
        <template  v-if="item.signStatus == 2 || item.signStatus == 3">
          <van-icon name="underway-o" size="15" />
          <span class="c-dete">{{item.signTime}}{{item.signStatus == 2 ? '拒签' : '已签'}}</span>
        </template>
      </div>

      <van-button
        v-if="item.signStatus == 1 && item.signPersonFlag"
        size="small"
        @click.prevent="goDetail"
        type="primary"
        >继续签收</van-button
      >
      <van-button v-else size="small" @click.prevent="goDetail"
        >查看明细</van-button
      >
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import copyCom from "@/components/copy-com.vue"
export default {
  components: {
    copyCom
  },
  props: {
    item: {
      type: Object,
    },
    type: {
      type: String,
    },
  },

  setup(props) {
    const router = useRouter();
    const goDetail = () => {
      router.push({
        name: "detail",
        params: { id: props.item.id },
      });
    };
    // 签收中：0，待签收：1，已拒签：2，已签收：3
    const signStatus = computed(() => {
      const name = props.item.signPersonName && ("(" + props.item.signPersonName + ")") || ''
      return props.item.signStatus == 0
        ? "签收中" + name
        : props.item.signStatus == 1
        ? "待签收"
        : props.item.signStatus == 2
        ? "已拒签" + name
        : "已签收" + name
    });

    const isShow = computed(() => {
      return props.type == "detail" ? false : true;
    });
    return {
      signStatus,
      goDetail,
      isShow,
    };
  },
};
</script>

<style lang="less" scoped>
.home-item-title {
  font-size: 15px;
  background: #fff;
  margin-top: 10px;
  padding: 0 10px 10px 10px;
  .home-item-her {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    span {
      margin-left: 3px;
    }
  }
  .fr {
    float: right;
    white-space: nowrap;
  }
}
.home-item-info {
  background-color: #f8f8f8;
  color: #666;
  border-top: 1px solid #e5e5e5;
  font-size: 12px;
  padding: 10px 0 10px 10px;
  .flex-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .l-left {
      white-space: nowrap;
    }
    .pag {
      color: #333;
      word-break: break-all;
    }
    .pags {
      color: #333;
      margin: 0 5px;
      
    }
  }
  .amount {
    color: red;
    margin-right: 5px;
  }
  
  
  p {
    line-height: 1.9;
    margin: 0;
  }
  .inline-bk {
    word-break: break-all;
  }
}
.home-btn {
  margin-top: 10px;
  font-size: 13px;
  color: #666;
  .c-dete {
    margin: 0 5px;
  }
}
</style>