<template>
  <div class="no-data-tip">
    <span class="iconfont icon-imgs icon-mini-vd"
      ><span class="path1"></span><span class="path2"></span
      ><span class="path3"></span><span class="path4"></span
      ><span class="path5"></span><span class="path6"></span
      ><span class="path7"></span><span class="path8"></span
      ><span class="path9"></span><span class="path10"></span
      ><span class="path11"></span><span class="path12"></span
      ><span class="path13"></span><span class="path14"></span
      ><span class="path15"></span><span class="path16"></span
      ><span class="path17"></span><span class="path18"></span
      ><span class="path19"></span><span class="path20"></span
      ><span class="path21"></span><span class="path22"></span
      ><span class="path23"></span><span class="path24"></span
    ></span>
    <span class="no-text">{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    }
  },
};
</script>
<style scoped>
.no-data-tip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;
  width: 100%;
  margin-top: 80px;
}

.no-data-tip .icon-imgs {
  font-size: 120px;
  height: 110px;
  line-height: 100px;
  color: #999;
}
.no-data-tip .no-text {
  display: flex;
  font-size: 13px;
  color: #999;
}
</style>
