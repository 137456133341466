<template>
  <div class="soso">
    <van-nav-bar left-arrow fixed placeholder @click-left="onClickLeft">
      <template #right>
        <van-search
            v-model="state.vsoso"
            autofocus
            show-action
            shape="round"
            placeholder="输入销售单位、单据编号、签收人"
            @search="onSearch"
            @clear="onClear"
            >
            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
      </template>
    </van-nav-bar>

    <van-list
        :immediate-check="false"
        v-model:loading="state.loading"
        :finished="state.finished"
        :finished-text="state.list.length == 0 ? '' : '没有更多了'"
        @load="onLoad"
        >
        <home-item
              v-for="(item, index) in state.list"
              :key="index"
              :item="item"
            />
    </van-list>
    <empty v-if="!state.loading && state.list.length == 0" title="暂无搜索结果" />
  </div>
</template>

<script>
import {  Search, Toast } from "vant";
import {  reactive, ref } from "vue";
import { deliveryNotices } from "@/api/home";
import homeItem from "@/components/home-item";
import empty from "@/components/empty";
import { useRouter } from "vue-router";
export default {
  components: {
    homeItem,
    empty,
    [Search.name]:Search
  },
  setup() {
    const routers = useRouter();
    const state = reactive({
      vsoso: '',
      list: [],
      loading: false,
      finished: false,
    });

    const page = ref(1)
    const onLoad = async () => {
        if(state.vsoso == '') {
          state.finished = true;
          state.loading = false;
          return
        }
        const list = state.list
        page.value = list.length == 0 ? 1 : page.value + 1
        const vl = page.value
        const listDb = await deliveryNotices({
            page: vl,
            size: 10,
            keyword: state.vsoso,
        });
        if(vl > 1) {
            state.list = list.concat(listDb.dataList)
        } else {
            state.list = listDb.dataList
        }
        state.loading = false;
        if (listDb.page == listDb.totalPage) {
            state.finished = true;
        }
    };

    const onClickLeft = () => {
      routers.push({ path: '/home' })
    };

    const onSearch = () => {
        if(state.vsoso == '') return Toast('请输入关键词')
        state.finished = false;
        state.loading = true;
        state.list = []
        page.value = 1
        onLoad()
    }

    const onClear = () => {
        state.list = []
    }

    return {
        state,
        onLoad,
        onClickLeft,
        onSearch,
        onClear
    };
  }
};
</script>

<style lang="less">
.soso {
    .van-nav-bar__right{
        width:85%;
        padding: 0;
    }
    .van-search {
        padding:0 10px 0 0;
        width: 100%;
    }
}


</style>
